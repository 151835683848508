<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
<div class="relative">
  <div class="vx-navbar-wrapper">
    <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">

      <!-- SM - OPEN SIDEBAR BUTTON -->
      <feather-icon class="sm:inline-flex xl:hidden cursor-pointer mr-3" icon="MenuIcon" @click.stop="showSidebar" />
      <!-- FULLSCREEN BUTTON -->
      <feather-icon class="sm:inline-flex cursor-pointer mr-3" :icon="fullscreenIcon" @click.stop="toggleFullscreen" />
      <!-- BACK TO MAIN BUTTON -->
      <feather-icon class="sm:inline-flex cursor-pointer mr-3" icon="HomeIcon" @click.stop="$router.push('/').catch(() => {});" />
      <!-- CLIENT ACTIONS BUTTONS -->
      <vx-tooltip v-if="!notAllowedToChangeClient"
        class="hidden sm:inline-flex cursor-pointer mr-3" text="Поиск посетителей" position="bottom">
        <feather-icon icon="UsersIcon" @click.stop="$router.push({ name: 'customer-search' }).catch(() => {});" />
      </vx-tooltip>
      <vx-tooltip v-if="!notAllowedToChangeClient"
        class="hidden sm:inline-flex cursor-pointer mr-3" text="Регистрация посетителя" position="bottom">
        <feather-icon icon="UserPlusIcon" @click.stop="$router.push({name: 'registration'}).catch(() => {})" v-if="$acl.check('cashier')" />
      </vx-tooltip>
      <vx-tooltip v-if="!notAllowedToChangeClient"
        class="hidden sm:inline-flex cursor-pointer mr-3" text="Выбрать посетителя по штрихкоду" position="bottom">
        <feather-icon icon="UserCheckIcon" @click.stop="activePrompt = true;" />
      </vx-tooltip>
      <vx-tooltip  class="hidden sm:inline-flex cursor-pointer mr-3" text="Завершить работу с посетителем" position="bottom">
        <feather-icon icon="UserXIcon" @click.stop="$store.dispatch('endClientSession')" />
      </vx-tooltip>

      <client-select-prompt :activePrompt.sync="activePrompt" />

      <vs-spacer></vs-spacer>

      <!-- BUG REPORT -->
      <feather-icon class="sm:inline-flex cursor-pointer" icon="AlertTriangleIcon" @click.stop="bugReportPrompt = true" />
      <vs-popup title="Отправить отчет об ошибке" :active.sync="bugReportPrompt">
        <video ref="bugVideo" class="hidden" />
        <div v-show="bugReport.screenshot" class="mb-4 text-center">
          <div>Скриншот:</div>
          <img ref="bugPhoto" src="" />
        </div>
        <div>
          <vs-textarea label="Описание ошибки" v-model="bugReport.description" />
        </div>
        <vs-divider />
        <div class="flex flex-wrap">
            <vs-button class="mb-2 mx-2 flex-auto" @click="captureScreen">СДЕЛАТЬ СКРИНШОТ</vs-button>
            <vs-button class="mb-2 mx-2 flex-auto" @click="submitBugReport">ОТПРАВИТЬ</vs-button>
            <vs-button class="mb-2 mx-2 flex-auto" @click="bugReportPrompt = false">ЗАКРЫТЬ</vs-button>
        </div>
      </vs-popup>

      <!-- NOTIFICATIONS -->
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" ref="notifcationDropdown">
        <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2 ml-6" :badge="notifications.length"></feather-icon>
        <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown" ref="notifcationDropdownMenu">

          <div class="notification-top text-center p-5 bg-primary text-white">
            <h3 class="text-white">{{ notifications.length }} новых</h3>
            <p class="opacity-75">Оповещения</p>
          </div>

          <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings">
            <ul class="bordered-items">
              <li v-for="ntf in notifications" :key="ntf.index" class="flex justify-between px-4 py-4 notification cursor-pointer"
                @click="editOrder(ntf.id);">
                <div class="flex items-start">
                  <feather-icon :icon="ntf.icon" :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
                  <div class="mx-2">
                    <span class="font-medium block notification-title" :class="[`text-${ntf.category}`]">{{ ntf.title }}</span>
                    <small>{{ ntf.msg }}</small>
                  </div>
                </div>
                <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.time) }}</small>
              </li>
            </ul>
          </VuePerfectScrollbar>
        </vs-dropdown-menu>
      </vs-dropdown>

      <!-- USER META -->
      <div class="the-navbar__user-meta flex items-center">
        <div class="text-right leading-tight hidden sm:block">
          <p class="font-semibold">{{ userName }}</p>
          <small>{{ userRole }}</small>
        </div>
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <div class="con-img ml-3">
            <img
              v-if="userImage.startsWith('http')"
              key="onlineImg"
              :src="userImage"
              alt="user-img"
              width="40"
              height="40"
              class="rounded-full shadow-md cursor-pointer block" />
            <img
              v-else
              key="localImg"
              :src="userImage"
              alt="user-img"
              width="40"
              height="40"
              class="rounded-full shadow-md cursor-pointer block" />
          </div>
          <vs-dropdown-menu class="vx-navbar-dropdown">
            <ul style="min-width: 9rem">
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="logout">
                <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
                <span class="ml-2">Выйти</span>
              </li>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

    </vs-navbar>
  </div>
</div>
</template>

<script>
var qs = require('qs');
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ClientSelectPrompt from '@/views/components/ClientSelectPrompt.vue';

export default {
  name: "the-navbar",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
      isFullscreen: false,
      activePrompt: false,

      bugReportPrompt: false,
      bugReport: {
        screenshot: null,
        description: ''
      }
    }
  },
  watch: {
    '$route'() {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
    }
  },
  computed: {
    fullscreenIcon() {
      return this.isFullscreen ? 'MinimizeIcon' : 'MaximizeIcon';
    },
    notifications() {
      return this.$store.state.notifications;
    },

    // HELPER
    sidebarWidth() {
        return this.$store.state.sidebarWidth;
    },
    breakpoint() {
        return this.$store.state.breakpoint;
    },

    // NAVBAR STYLE
    classObj() {
        if (this.sidebarWidth == "default") return "navbar-default"
        else if (this.sidebarWidth == "reduced") return "navbar-reduced"
        else if (this.sidebarWidth) return "navbar-full"
    },

    // PROFILE
    userRole() {
      if (localStorage.getItem('userRole')) {
        return localStorage.getItem('userRole')
      }
    },
    userName() {
      if (localStorage.getItem('userInfo')) {
        return JSON.parse(localStorage.getItem('userInfo')).user_name
      }
    },
    userImage() {
      if (localStorage.getItem('userInfo')) {
        let image = JSON.parse(localStorage.getItem('userInfo')).photo;
        return this.$helpers.getImageSrc(image);
      } else {
        return this.$helpers.getImageSrc('');
      }
    },

    notAllowedToChangeClient() {
      return (!this.$store.getters['activeTimeTariff'] && this.$store.state.activeClient.activeVisit);
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    toggleFullscreen() {
      let element = document.documentElement;
      element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen || function () { return false; };
      document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msCancelFullscreen || function () { return false; };
      this.isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
    },
    fullscreenHandler() {
      this.isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || (document.fullScreenElement && document.fullScreenElement !== null) || false;
    },

    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? ' лет ' : ' год ') + 'назад';
      } else if (days > 0) {
        return days + (days > 1 ? ' дней ' : ' день ') + 'назад';
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' часов ' : ' час ') + 'назад';
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' минут ' : ' минуту ') + 'назад';
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' сек назад' : 'сейчас');
      }

      return 'сейчас'
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },

    editOrder(order_id) {
      this.$refs.notifcationDropdown.vsDropdownVisible = false;
      this.$refs.notifcationDropdownMenu.dropdownVisible = false;
      this.$store.dispatch('getOrder', [
        order_id,
        function(){}, 
        () => {
          this.$router.push({ name: 'edit-order' }).catch(() => {});
        } 
      ]);
    },

    captureScreen() {
      this.bugReportPrompt = false;

      let video = this.$refs.bugVideo;
      navigator.mediaDevices.getDisplayMedia({
        audio: false
      }).then((result) => {
        video.srcObject = result;
        video.play();

        let scale = 1;
        let canvas = document.createElement("canvas");
        canvas.width = window.screen.width * scale;
        canvas.height = window.screen.height * scale;

        setTimeout(() => { 
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height); 

          let img = canvas.toDataURL('image/png');
          let photo = this.$refs.bugPhoto;

          photo.width = 320;
          photo.height = 240;
          photo.setAttribute('src', img);

          let tracks = video.srcObject.getTracks();
          tracks.forEach(track => track.stop());
          video.srcObject = null;

          this.bugReport.screenshot = img;
          this.bugReportPrompt = true;
        }, 1000);

      }).catch((err) => {
        // console.log('err :', err);  
      });
    },

    submitBugReport() {
      let payload = {
        name: "Баг-репорт",
        data: {
          new: 
            Object.assign({}, this.bugReport)
        }
      }
      payload.data.new.time = new Date().toString();
      payload.data.new.vuex_state = JSON.stringify(this.$store.state);
      payload.data.new.manager_id = JSON.parse(localStorage.getItem('userInfo')).user_id;

      this.$http({
        method: 'post',
        url: '/udata/users/submit_bug_report/.json',
        data: qs.stringify(payload),
        withCredentials: true
      }).then((response) => {
        this.bugReportPrompt = false;
        this.$vs.notify({
          title:'Сообщение отправлено',
          text: 'Номер вашего отчета об ошибке: ' + response.data.bug_report_id,
          color:'success',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      })
      .catch((error) => {
        this.$vs.notify({
          title:'Ошибка',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('fullscreenchange', this.fullscreenHandler, false);
      document.addEventListener('mozfullscreenchange', this.fullscreenHandler, false);
      document.addEventListener('msfullscreenChange', this.fullscreenHandler, false);
      document.addEventListener('webkitfullscreenchange', this.fullscreenHandler, false);
    })
  },
  components: {
    VuePerfectScrollbar,
    ClientSelectPrompt
  }
}
</script>
